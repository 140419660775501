import { IStatuses } from '@/store/typings/inventory'
import { ManipulateType } from 'dayjs'

const CHART_COLORS: string[] = [
  '#42A5F5',
  '#EF5350',
  '#AB47BC',
  '#66BB6A',
  '#FFCA28',
  '#BDBDBD',
  '#D4E157',
  '#FFEE58',
  '#FFA726',
  '#FF7043',
  '#8D6E63',
  '#78909C',
  '#7E57C2',
  '#5C51E3',
  '#D95CE3',
  '#29B6F6',
  '#26C6DA',
  '#26A69A',
  '#9CCC65',
  '#4cafff',
  '#f95d5a',
  '#b551c6',
  '#70c574',
  '#ffd432',
  '#97786d',
  '#c7c7c7',
  '#deeb61',
  '#fff862',
  '#ffb130',
  '#ff7a4d',
  '#829aa6',
  '#8861cc',
  '#665bed',
  '#e366ed',
  '#33c0ff',
  '#30d0e4',
  '#30b0a4',
  '#a6d66f',
  '#56b9ff',
  '#ff6764',
  '#bf5bd0',
  '#7acf7e',
  '#ffde3c',
  '#a18277',
  '#d1d1d1',
  '#e8f56b',
  '#ffff6c',
  '#ffbb3a',
  '#ff8457',
  '#8ca4b0',
  '#926bd6',
  '#7065f7',
  '#ed70f7',
  '#3dcaff',
  '#3adaee',
  '#3abaae',
  '#b0e079',
  '#399cec',
  '#e64a47',
  '#a23eb3',
  '#5db261',
  '#f6c11f',
  '#84655a',
  '#b4b4b4',
  '#cbd84e',
  '#f6e54f',
  '#f69e1d',
  '#f6673a',
  '#6f8793',
  '#754eb9',
  '#5348da',
  '#d053da',
  '#20aded',
  '#1dbdd1',
  '#1d9d91',
  '#93c35c',
  '#3093e3',
  '#dd413e',
  '#9935aa',
  '#54a958',
  '#edb816',
  '#7b5c51',
  '#ababab',
  '#c2cf45',
  '#eddc46',
  '#ed9514',
  '#ed5e31',
  '#667e8a',
  '#6c45b0',
  '#4a3fd1',
  '#c74ad1',
  '#17a4e4',
  '#14b4c8',
  '#149488',
  '#8aba53'
]

const COLOR_PALETTE: string[] = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#3F51B5',
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#CDDC39',
  '#FFEB3B',
  '#FF9800',
  '#000000',
  '#1c1c1c',
  '#353535',
  '#4f4f4f',
  '#686868',
  '#818181',
  '#9a9a9a',
  '#b3b3b3',
  '#cdcdcd',
  '#e6e6e6',
  '#f59896',
  '#f48785',
  '#f27573',
  '#f16462',
  '#EF5350',
  '#d74b48',
  '#bf4240',
  '#a73a38',
  '#8f3230',
  '#782a28',
  '#ffb8a1',
  '#ffa98e',
  '#ff9b7b',
  '#ff8d69',
  '#ff7e56',
  '#ff7043',
  '#e6653c',
  '#cc5a36',
  '#b34e2f',
  '#994328',
  '#ffe594',
  '#ffdf7e',
  '#ffda69',
  '#ffd553',
  '#ffcf3d',
  '#ffca28',
  '#e6b624',
  '#cca220',
  '#b38d1c',
  '#997918',
  '#b3ddb5',
  '#a3d6a6',
  '#94cf97',
  '#85c988',
  '#75c279',
  '#66bb6a',
  '#5ca85f',
  '#529655',
  '#47834a',
  '#3d7040',
  '#a1d2fa',
  '#8ec9f9',
  '#7bc0f8',
  '#68b7f7',
  '#55aef6',
  '#42a5f5',
  '#3b95dd',
  '#3584c4',
  '#2e73ac',
  '#286393',
  '#aea8f1',
  '#9d97ee',
  '#8d85eb',
  '#7d74e9',
  '#6c62e6',
  '#5c51e3',
  '#5349cc',
  '#4a41b6',
  '#40399f',
  '#373188',
  '#d5a3de',
  '#cd91d7',
  '#c47ed0',
  '#bc6cc9',
  '#b359c3',
  '#ab47bc',
  '#9a40a9',
  '#893996',
  '#783284',
  '#672b71'
]

const DATE_RELATED_FIELD_NAMES: string[] = ['Date', 'Week', 'Month', 'Quarter', 'Year', 'Hour', 'DateHour', 'DateHourMinute']

const RESOURCE_TYPES: { [key: string]: TResourceType } = {
  dashboardPage: 'dashboard',
  inventoryPage: 'inventory',
  reportsPage: 'report'
}

const INVENTORY_EVENTS_STATUSES: IStatuses[] = ['Active', 'Archived']

const ASC_DESC_MAP = { ascending: 'ASC', descending: 'DESC' }

const SERVER_DATE_FORMAT: string = 'YYYY-MM-DD'
const SERVER_DATE_HOUR_FORMAT: string = 'YYYY-MM-DD HH:mm:ss'

const CUSTOM_GLOBAL_UUID: string = '00000000-0000-0000-0000-000000000000'
const MAIN_DASHBOARD_CUSTOM_UUID: string = '00000000-0000-0000-0000-000000000001'

const IMAGE_FORMATS = ['jpg', 'jpeg', 'png', 'gif', 'webp']
const VIDEO_FORMATS = ['mp4', 'webm', 'avi']
const PLAYABLE_FORMATS = ['html', 'htm']

const SORT_ORDERS = ['descending', 'ascending', null]
const SORT_ORDERS_ASC = ['ascending', 'descending', null]

const NULLABLE_FILTER_VALUE = 'null'

const DATE_UNITS_MAP: { [type: string]: ManipulateType } = {
  Date: 'day',
  Week: 'week',
  Month: 'month'
}

const MULTIPLE_UA_CREATIVE_SCHEMA_ID = '0304abb7-d4dc-4365-bdb3-6fa0b7159a07'

const ERROR_NAMES: Record<string, string> = {
  accessDenied: 'access_denied'
}

const AUTH_ERRORS: Record<string, string> = {
  tokenExpiredResetPassword: 'Your request to reset password has expired. Please try again.',
  tokenExpiredJoinOrganization: 'Your invite to join the organization has expired. Please try again.',
  tokenExpiredSignUp: 'The registration link you used is no longer valid',
  unknownError: 'An unknown error occurred',
  access_denied: 'Access denied',
  userExists:
    'It looks like you have already registered with this email. Try to <a href="/login">log in</a> or <a href="/recover-password">reset your password</a>.'
}

const AUTH_SUCCESS: Record<string, string> = {
  joinSuccessful: 'joinSuccessful',
  passwordChangeSuccessful: 'passwordChangeSuccessful'
}

const AUTH_MESSAGES: Record<string, string> = {
  accountActivated: 'Your account was successfully activated',
  joinSuccessful: 'You have successfully joined the organization',
  passwordChangeSuccessful: 'Password successfully changed. Please log in with your new password'
}

const AUTH_TOKENS = {
  tokenExpiredResetPassword: 'tokenExpiredResetPassword',
  tokenExpiredJoinOrganization: 'tokenExpiredJoinOrganization',
  tokenExpiredSignUp: 'tokenExpiredSignUp'
}

const INFO_PANEL_TYPES = {
  error: 'error',
  info: 'info',
  warning: 'warning',
  success: 'success',
  note: 'note'
}

const MAX_UPLOAD_FILE_SIZE = 2147483646

export {
  ASC_DESC_MAP,
  CHART_COLORS,
  COLOR_PALETTE,
  DATE_RELATED_FIELD_NAMES,
  RESOURCE_TYPES,
  SERVER_DATE_FORMAT,
  SERVER_DATE_HOUR_FORMAT,
  CUSTOM_GLOBAL_UUID,
  MAIN_DASHBOARD_CUSTOM_UUID,
  IMAGE_FORMATS,
  VIDEO_FORMATS,
  PLAYABLE_FORMATS,
  SORT_ORDERS,
  SORT_ORDERS_ASC,
  NULLABLE_FILTER_VALUE,
  INVENTORY_EVENTS_STATUSES,
  DATE_UNITS_MAP,
  MULTIPLE_UA_CREATIVE_SCHEMA_ID,
  ERROR_NAMES,
  AUTH_ERRORS,
  AUTH_SUCCESS,
  AUTH_MESSAGES,
  AUTH_TOKENS,
  INFO_PANEL_TYPES,
  MAX_UPLOAD_FILE_SIZE
}
